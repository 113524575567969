import HeadFile from "../components/head-file";
import { useState, useEffect, useContext } from "react";
import { httpRequest, navigate } from "../utils/functions";
import AuthContext from "../context/auth-context";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useRouter } from "next/router";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [btnText, setBtnText] = useState("Login");

  const { GLOBAL_OBJ, AUTH_LOGIN, AUTH_LOGOUT } = useContext(AuthContext);  
  
  const router = useRouter();

  async function handleLogin() {
    setBtnText("Authenticating...");
    setResponseMsg("");
    var data = {
      email: email,
      password: pass,
    };

    //make request
    const URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/extention/login?country_code=${GLOBAL_OBJ.country_code}`;
    var r = await httpRequest(URL, "post", data, {
      headers: { "x-user-agent": "User Swoove Super-Admin-Panel 1.0.0" },
    });
    // console.log(URL)
    // console.log(r.responses)
    if (r && r.code === 200) {
      // console.log(r)
      r = r.responses;
      // setResponseMsg("Login successful")
      //console.log("Login successful")
      //setBtnText("Redirecting...")
      AUTH_LOGIN({
        token: r.token,
        location: r.location,
        yasira:'yasira',
        email: r.email,
        user: r.access_level,
      });
    } else if (r.code === 401 || r.code === 400) {
      setResponseMsg("Unauthorized");
      // console.log("unauthorized")
      setBtnText("Login");
      toast.error(r.message);
    } else {
      setResponseMsg("Login failed");
      // console.log("Login failed")
      setBtnText("Login");
      toast.error(r.message);
    }
  }

  function request_password_reset() {
    swal({
      text: "Enter email address",
      content: "input",
      button: {
        text: "Request",
        closeModal: false,
      },
    })
      .then(async (email) => {
        if (!email) throw null;
        let payload = {
          email: email,
        };
        let url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/v1.0/extention/super-admin/password-reset-request`;
        let r = await httpRequest(url, "put", payload, {
          headers: { "x-user-agent": "User Swoove Super-Admin-Panel 1.0.0" },
        });

        if (r.code === 200) {
          // console.log(r.responses)
          // console.log(r.responses)
          //toast.info(r.message)
          navigate("reset", { email: email });
          swal.stopLoading();
          swal.close();
        } else if (r.code === 401 || r.code === 400) {
          toast.error(r.message);
          swal.stopLoading();
          swal.close();
        } else {
          toast.error(r.message);
          swal.stopLoading();
          swal.close();
        }
      })
      .catch((err) => {
        toast.error(err);
        swal.stopLoading();
        swal.close();
      });
  }
  useEffect(() => {
    // console.log(GLOBAL_OBJ);
    // GLOBAL_OBJ.isLoggedIn && process.browser && navigate("deliveries");
    if (document.readyState === "complete" && GLOBAL_OBJ.isLoggedIn) {
      router.push("/deliveries");
    }
  }, [GLOBAL_OBJ]);

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#F15A24",
      }}
    >
      <HeadFile title="Login | Swoove Super Admin" />
      <main
        className="main-content"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="bg-white rounded shadow-7 w-400 mw-100 p-6"
          style={{ marginTop: "3em" }}
        >
          <h2 className="mb-7 text-center">Sign in</h2>

          <form>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                name="email"
                data-testid="login-form-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>

            <div className="form-group">
              <input
                type="password"
                className="form-control"
                name="password"
                data-testid="login-form-password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                placeholder="Password"
              />
            </div>

            <p>
              Forgetten password? Request password rest{" "}
              <span
                onClick={request_password_reset}
                style={{ color: "cyan", cursor: "pointer" }}
              >
                here
              </span>
            </p>

            <div className="form-group">
              <button
                data-testid="login-form-button"
                className="btn btn-block btn-primary"
                style={{
                  background: "#F15A24",
                  borderColor: "#F15A24",
                  color: "black",
                }}
                type="button"
                onClick={handleLogin}
              >
                {btnText}
              </button>
            </div>
          </form>
          <div>
            <p
              className="text-center"
              data-testid="login-form-response"
              style={{
                color: "#F15A24",
                textTransform: "uppercase",
                fontSize: "12px",
              }}
            >
              {responseMsg}
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}
